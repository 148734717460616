const _PROVINCES = [
    'BULAWAYO',
    'HARARE',
    'MANICALAND',
    'MASHONALAND CENTRAL',
    'MASHONALAND EAST',
    'MASHONALAND WEST',
    'MASVINGO',
    'MATEBELELAND NORTH',
    'MATEBELELAND SOUTH',
    'MIDLANDS'
]

export default _PROVINCES;