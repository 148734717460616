const SEX = [
    {
        label: "BOYS",
        value: "MALE"
    },
    {
        label: "GIRLS",
        value: "FEMALE"
    },
    {
        label: "MIXED",
        value: "MIXED"
    },
]

export default SEX;