const VEHICLE_TYPE = [
    "BUS",
    "CAR",
    "MINIBUS",
    "MOTORCYCLE",
    "TRUCK",
    "TRACTOR",
    "OTHER"
]

export default VEHICLE_TYPE;