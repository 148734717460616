const INSTITUTION_OWNER_TYPES = [
    {
        label: "GOVERNMENT",
        value: "Government"
    },
    {
        label: "PRIVATE",
        value: "Private"
    }
]

export default INSTITUTION_OWNER_TYPES;