export const COURSEWORK_TYPES = [
    { label: "ASSIGNMENT", value: "ASSIGNMENT" },
    { label: "HOMEWORK", value: "HOMEWORK" },
    { label: "PRESENTATION", value: "PRESENTATION" },
    { label: "INCLASS TEST", value: "INCLASS TEST" },
    { label: "EXAM", value: "EXAM" },
    { label: "CALA", value: "CALA" },
    { label: "GROUP WORK", value: "GROUP WORK" },
    { label: "PROJECT", value: "PROJECT" },
    { label: "QUIZ", value: "QUIZ" },
    { label: "LAB WORK", value: "LAB WORK" },  
]