const RELIGIONS = [
    "CHRISTIANITY",
    "ISLAM",
    "AFRICAN TRADITIONAL RELIGION",
    "HINDUISM",
    "JUDAISM",
    "BUDDHISM",
    "RASTAFARI",
    "NONE",
    "OTHER"
]

export default RELIGIONS;