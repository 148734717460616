export const VEHICLE_REPAIR_STATUS = [
    "IS FIXED",
    "PARTIALLY FIXED",
    "NOT FIXED",
];

export const VEHICLE_DAMAGE_SEVERITY = [
    "MINOR",
    "MAJOR",
    "CRITICAL",
    "BEYOND REPAIR",
]
