const INSTITUTION_TYPES = [
    {
        label: "BOARDING",
        value: "BOARDING"
    },
    {
        label: "DAY",
        value: "DAY"
    },
    {
        label: "DAY/BOARDING",
        value: "DAY/BOARDING"
    }
]

export default INSTITUTION_TYPES;