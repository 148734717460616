const TITLES = [
    {
        label: "MR",
        value: "MR"
    },
    {
        label: "MRS",
        value: "MRS"
    },
    {
        label: "DR",
        value: "DR"
    },
    {
        label: "PROF",
        value: "PROF"
    },
]

export default TITLES;