const TRIP_PURPOSE = [
    "EDUCATIONAL",
    "PROCUREMENT",
    // "RECREATION",
    // "RELIGIOUS",
    "SPORTS",
    "STAFF TRANSPORT",
    "NON-STAFF TRANSPORT",
    // "PRODUCT_DELIVERY",
    // "COMMUNITY_SERVICE",
    // "OTHER"
]

export default TRIP_PURPOSE;